import React from 'react';
import CountUp from 'react-countup';

const CounterHook = () => {
  // useCountUp({ start: 100, end: 18000, delay: 5, duration: 0, enableScrollSpy: true });
  return (
    <>
      <CountUp
        start={10000}
        end={18000}
        // delay={10}
        duration='3'
        //   enableScrollSpy={true}
        scrollSpyOnce={true}
      />
    </>
  );
};

export default CounterHook;
