export const images = [
  {
    id: 'charity-1',
    name: 'Black Business and Professionals Association',
    link: require('../Assets/Images/charities/bboa-logo.png'),
  },
  {
    id: 'charity-2',
    name: 'Careers Education Empowerment',
    link: require('../Assets/Images/charities/CEE_logo PNG.png'),
  },
  {
    id: 'charity-3',
    name: 'Evas',
    link: require('../Assets/Images/charities/evas.png'),
  },
  {
    id: 'charity-4',
    name: 'Saint Lucia Rotary',
    link: require('../Assets/Images/charities/rotary.png'),
  },
  // {
  //    id: 'charity-5',
  //    name: 'inner city',
  //    link: require('../Assets/Images/charities/innercity.jpeg'),
  // },
  {
    id: 'charity-6',
    name: 'keep six',
    link: require('../Assets/Images/charities/keepsix.png'),
  },
  {
    id: 'charity-7',
    name: 'kickback',
    link: require('../Assets/Images/charities/kickback.png'),
  },
  {
    id: 'charity-8',
    name: 'tropicana',
    link: require('../Assets/Images/charities/tropicana.png'),
  },
  {
    id: 'charity-9',
    name: 'youth assisting',
    link: require('../Assets/Images/charities/youthassisting.png'),
  },
  {
    id: 'charity-10',
    name: 'Youth Leaps',
    link: require('../Assets/Images/charities/youthleaps.png'),
  },
];
