import React from 'react';
import Marquee from 'react-fast-marquee';

import '../Styles/marquee.css';
import { testimonials } from '../Objects/testimonials';

const MarqueeCard = (props) => {
  return (
    <Marquee gradient={false} speed={props.speed} pauseOnClick={true}>
      {
        <span style={{ display: 'flex' }}>
          {testimonials.map((data) => {
            return (
              <ul>
                <li
                  class='card'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <img
                    alt=''
                    src={data.logo}
                    //   width='100'
                    height='90'
                    style={{ borderRadius: '15px' }}
                  />
                  <p style={{ marginTop: '5px', marginBottom: '0px' }}>
                    {data.message}
                  </p>
                  <p> - " {data.name} "</p>
                </li>
              </ul>
            );
          })}
        </span>
      }
    </Marquee>
  );
};

export default MarqueeCard;
