import React from 'react';
// import ReactGA from 'react-ga';
//Custom Imports
import '../Styles/Main.css';
import CounterHook from '../hooks/counter';
import MarqueeHook from '../hooks/marquee';
import MarqueeCard from '../hooks/marqueeCard';
import SubscriberForm from '../Services/SubscriberForm';

const HomeComponent = () => {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // });
  return (
    <div className='main'>
      <div className='section-one'>
        <div className='s1-content'>
          <header className='header'>
            <img
              src={require('../Assets/Images/logo.png')}
              alt=''
              className='mfc-logo'
            />
            <h1 className='s1-title'>Move For Change</h1>
          </header>
          <div>
            <p className='s1-about'>
              As Move For Change strives to unite individuals around the world
              for a greater purpose, it is up to each and every one of us to be
              the change we want to see. Will you answer the challenge? Are you
              ready to compete for something bigger than yourself? Join the
              community and see how far you can push your limits – all in
              support of a good cause. Don't miss out on the exclusive
              opportunity to be one of our Beta testers and stay ahead of your
              peers with all new updates! Simply subscribe with your email now
              for exciting updates that we've got in store! Move For Change App
              Coming Soon!
            </p>
          </div>
        </div>
        <div className='s1-form'>
          <SubscriberForm />
        </div>
      </div>
      <div className='section-two'>
        <h2 className='s2-title'>Create Your Own Movement</h2>
        <p className='s2-subTitle'>
          Move For Change is an app that allows runners to create movements and
          donate to charity. The app tracks a runner's distance travelled, and
          recommends a donation based on total kilometers. Runners can create
          movements to represent an event where 1 or more persons can run for a
          cause. Each movement has a charity associated, and runners can donate
          to the charity of their choice. The app also allows runners to honor
          someone by making a donation in their name. move for change is a
          community of movers who are dedicated to making a difference.
        </p>
        <div className='s2-list'>
          <div className='s2-list-item'>
            <img
              src={require('../Assets/Images/movements/login.png')}
              className='s2-listImg'
              alt=''
            />
            <h3 className='s2-itemTitle'>Move For Change</h3>
            <p className='s2-itemSubTitle'>
              Move For Change is a community of movers who are dedicated to
              making a difference.
            </p>
          </div>
          <div className='s2-list-item'>
            <img
              src={require('../Assets/Images/movements/profile.png')}
              className='s2-listImg s2-img-2'
              alt=''
            />
            <h3 className='s2-itemTitle'>Create A Move-Ment</h3>
            <p className='s2-itemSubTitle'>
              Create a Move-ment for a cause that is near and dear to your
              heart.<span className='dummy-text'>to align images</span>
            </p>
          </div>
          <div className='s2-list-item'>
            <img
              src={require('../Assets/Images/movements/movements.png')}
              className='s2-listImg'
              alt=''
            />
            <h3 className='s2-itemTitle'>Make A Difference</h3>
            <p className='s2-itemSubTitle'>
              Invite friends and family to give back to a charity that best
              aligns with your beliefs.
              <span className='dummy-text'>to align images here</span>
            </p>
          </div>
          <div className='s2-list-item'>
            <img
              src={require('../Assets/Images/movements/home.png')}
              className='s2-listImg s2-img-4'
              alt=''
            />
            <h3 className='s2-itemTitle'>Now…Get Out there and Move</h3>
            <p className='s2-itemSubTitle'>
              Get out there and Move For Change!
              <span className='dummy-text'>
                to align images here and here and here and here and here here
                and here and here
              </span>
            </p>
          </div>
          <div></div>
        </div>
      </div>
      <div className='section-three'>
        <div className='s3-body'>
          <div className='s3-items'>
            <h2 className='s3-title'>
              We have raised more than{' '}
              <span style={{ color: '#89D938' }}>
                $<CounterHook /> CAD
              </span>{' '}
              since June 2020.
            </h2>
            <p className='s3-subTitle'>
              Our appreciation for these charities and what they do for the
              community is unwavering. We are so thankful to have been able to
              partner with them, as without them our work would not be possible.
              It’s a beautiful thing when people come together, especially
              during times of hardship or need, and it is even more amazing to
              see how charities can help so many others in need. We look forward
              to continuing our relationships with those organizations already
              on board and seeking out new ones further down the line. Thank you
              all again! Let’s work together to create a better future for
              everyone by #MOVEFORCHANGE.
            </p>
          </div>
          <div>
            <MarqueeHook speed={15} />
          </div>
          <div>
            <p className='s3-subTitle2'>
              Don't miss out on the exclusive opportunity to be one of our Beta
              testers and stay ahead of your peers with all new updates! Simply
              subscribe with your email now for exciting updates that we've got
              in store! Move For Change App Coming Soon!
            </p>
          </div>
          <br />
          <div className='s3-btn'>
            <SubscriberForm />
          </div>
        </div>
      </div>
      <div className='section-four'>
        <div className='s4-content'>
          <h2 className='s4-title'>What The Community Is Saying...</h2>
          <blockquote className='marquee-card'>
            <MarqueeCard />
          </blockquote>
        </div>
      </div>
      <footer>
        <section className='footer'>
          <div className='copyright'>
            <p>Copyright &copy; 2023 MOVE FOR CHANGE</p>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default HomeComponent;
