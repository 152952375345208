import React from 'react';
import Marquee from "react-fast-marquee";

import '../Styles/marquee.css';
import {images} from '../Objects/images'

const MarqueeHook = (props) => {
   
   return (
      <Marquee
         gradient={true}
         gradientColor={[75, 75, 75]}
         gradientWidth={40}
         speed={props.speed}
         pauseOnClick={true}
      
      >
         <span>
         
         {
            images.map((imageData) => {
               return <img
                  key={imageData.id}
                  src={imageData.link}
                  className="img"
                  alt={imageData.name}
               />
            })
   
   
         }
         </span>
      </Marquee>
   )
}

export default MarqueeHook;