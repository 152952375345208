import React from 'react';
// import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga';
//Custom Imports
import HomeComponent from './Components/HomePage';
import ScrollToTop from 'react-scroll-to-top';
import { FaAngleUp } from 'react-icons/fa';

const App = () => {
  // const location = useLocation();
  // ReactGA.initialize('G-P990W4ZLSC');
  return (
    <>
      <ScrollToTop
        smooth
        style={{ borderRadius: '50%' }}
        component={
          <FaAngleUp style={{ color: '#4B4B4B', borderRadius: '50%' }} />
        }
      />
      <HomeComponent />
    </>
  );
};

export default App;
