export const testimonials = [
  {
    id: 'donation-one',
    name: 'Ed Carlson, Director of Development, Youth Assisting Youth',
    message:
      'Move For Change is an amazing way for anyone to support the charities that they love, while also staying active and living a healthy lifestyle! We’ve had the pleasure of working with Julian and Move For Change since its inception, and we’re so grateful for the funds that they’ve raised for our youth mentoring program',
    logo: require('../Assets/Images/charities/youthassisting.png'),
  },
  {
    id: 'donation-two',
    name: 'Ernest Noeline (President of RCGI 2022-2023)',
    message:
      'I had the privilege and honor to meet Mr Julian Compton, a wonderful person and a great humanitarian. His selflessness is a virtue, helping the needy and making real improvements to people’s lives. As President of the Rotary Club of Gros Islet from the Island of St Lucia, I am proud to be a recipient of the "move for change" initiative. Thank you.',
    logo: require('../Assets/Images/charities/rotary.png'),
  },
];
