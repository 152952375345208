import React, { useState } from "react";
import axios from "axios";
import '../Styles/Main.css'


const SubscriberForm= (props) => {
   const [email, setEmail] = useState("");
   const [message, setMessage] = useState("");
   
   const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          await axios.post(
            `https://us-central1-move-for-change.cloudfunctions.net/addSubscriber2`,
            {
               email: email,
               fname: "Website",
               lname: "Subscriber"
            },
             {
                auth: {
                   username: "Move For Change",
                   password: `"${process.env.REACT_APP_API_KEY}"`,
                }
             }
         );
   
         setMessage("Email Subscribed successfully!");
            setEmail('');
      } catch (error) {
         setMessage("Subscribe failed, please try again.");
      }
   };
   
   return (
      <>
      
      <form className="mfc-form" onSubmit={handleSubmit}>
         <input
            className="input"
            placeholder='Your Email Address'
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
         />
         <button className='btn' type="submit">Subscribe</button>
         <p style={{color: 'white', textAlign: 'center'}} className="form-msg">{message}</p>
      </form>
      
      </>
   );
};

export default SubscriberForm;